import React, { useEffect } from 'react'
import uscLogoImage from '../../assests/img/inzalousc.png';
import M from "materialize-css/dist/js/materialize.min.js";
import { Link } from 'react-router-dom';


const Navbar = () => {

    useEffect(() => {
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, {});

    }, [])

    return (
        <div>
            <nav>
                <div className="nav-wrapper white">
                    <Link to="" data-target="slide-out" className="right sidenav-trigger black-text text-darken-4">
                        <i className="material-icons show-on-large">menu</i>
                    </Link>
                    <div className="container">
                        <Link to="/" className="brand-logo"><img src={uscLogoImage} alt="Logo" width="150" height="40" /></Link>
                        <ul id="nav-mobile" className="right hide-on-med-and-down black-text" >
                            <li><Link to="/" className="blue-text text-darken-4"><b>Home</b></Link></li>
                            <li><Link to="/about" className="blue-text text-darken-4"><b>About</b></Link></li>
                            {/* <li><Link to="/contant-us" className="black-text text-darken-4">Contact Us</Link></li> */}
                        </ul>
                    </div>
                </div>
            </nav>
            

            <ul id="slide-out" className="sidenav sidenav-close">
                <li>
                    <div className="user-view">
                        <div className="brand-logo">
                            <img src={uscLogoImage} alt="Logo" width="250" height="70" />
                        </div><br />
                    </div>
                </li>
                <li><div className="divider"></div></li>
                <li>
                    <Link to="/"><i className="material-icons">home</i>Home
                    </Link>
                </li>
                <li>
                    <Link to="/about" className="black-text text-darken-4 active">
                        <i className="material-icons ">info</i>About
                    </Link>
                </li>
                {/* <li>
                    <Link to="/contact-us" className="black-text text-darken-4">
                        <i className="material-icons">mail_outline</i>Contact Us
                    </Link>
                </li> */}


            </ul>
        </div>
    )
}
export default Navbar;
