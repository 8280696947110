import React from 'react'

const AboutPage = () => {
    return (
        <div className="container grey-text text-darken-1" style={{ marginTop: 20 }}>
            <div className="card-panel white row hoverable">
                <div className="details blue-text text-darken-4">
                    <div className="center-align blue-text"><h5><b>About Inzalo Utility Systems</b></h5></div>
                </div>
            </div>

            <div className="container black-text">
                <p>Utility Systems is an industry leader in the field of remote communicating electronic water control valves and STS prepayment devices.</p>
                <p>Established in 2001, the company offers cutting-edge smart digital technology for water metering and has a global customer base.</p>
                <p>Our water management device, the WMD, with Liquid Crystal Display (LCD) can be coupled to most pulse output water meters. It converts analogue water meters into smart meters to provide:</p>
                <ul>
                    <ol>Free basic water.</ol>
                    <ol>STS prepayment.</ol>
                    <ol>Automated meter reading (AMR).</ol>
                    <ol>Advanced metering infrastructure (AMI).</ol>
                    <ol>Bulk water management.</ol>
                </ul>
                <p>All of our products are designed and manufactured in an ISO 9001: 2008 certified facility. As market leaders, Utility Systems’ success can be attributed to:</p>
                <ul>
                    <ol>High levels of innovation based on user requirements.</ol>
                    <ol>Extensive research and development.</ol>
                    <ol>Our dedicated product support and training team.</ol>
                    <ol>Commitment to product quality.</ol>
                    <ol>Utility Systems is in the ideal position to offer the market the latest in next-generation water management products.</ol>
                </ul>
            </div>


        </div>
    )
}
export default AboutPage; 