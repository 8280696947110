import React from 'react';
import NotFound from "../../assests/img/not-found.gif"

const NotFoundPage = () => {
    return (
        <div className="container">

            <div className="card-panel white row hoverable">
                <div className="details blue-text text-darken-4">
                    <div className="center-align blue-text"><h5><b>Oops! That page can’t be found.</b></h5></div>
                </div>
            </div>
            <b> It looks like this was the result of either:
            <ul>
                <li>a mistyped address</li>
                <li>an out-of-date link</li>
            </ul>
            </b>
            <div className="container">
                <img className="responsive-img" src={NotFound} alt="loading..." />
            </div>
        </div>
    )
}
export default NotFoundPage;