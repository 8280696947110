import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { Service } from '../../utility/Service';
import { Modal } from 'antd';
import BarcodeScannerComponent from "react-webcam-barcode-scanner";

export const LandingPage = () => {
    const [serialNumber, setSerialNumber] = useState(41);
    const [loading, setLoading] = useState(false);
    const [isScanning, setIsScanning] = useState(false);

    const validateForm = () => {
        let isValid = true;
        if (serialNumber.length === 11) {
            isValid = false;
        }
        return isValid;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        
        Service.demoApi.get(`CustomerSupport/GetWarrantyInfo?serialNumber=${serialNumber}`)
            .then(res => {
                console.log(res.data);
                let data = res.data;
                if (data !== null) {
                    let param = {
                        meterNumber: data.serialNumber,
                        companyName: data.companyName,
                        jobId: data.jobId,
                        periodInMonths: data.periodInMonths,
                        productionDate: GetDate(data.productionDate),
                        expiryDate: GetDate(data.expiryDate),
                        isUnderWarranty: data.isUnderWarranty
                    }
                    // PASS DATA TO POPUP DIALOG
                    success(param);
                }
                else {
                    let data = `Serial number not found  : ${serialNumber}`
                    error(data);
                }
            }).catch(er => {
                error('Error connecting to the API');
                console.log(er);
            })
    };

    const GetDate = (datetime) => {
        let date = new Date(datetime);
        return date.toDateString();
    }

    const error = (message) => {
        setLoading(false);
        Modal.error({
            onOk: () => setSerialNumber(41),
            content: (
                <div>
                    <h5><b>Warranty Checker</b></h5>
                    <div style={{ marginLeft: 40 }}>
                        <div>{message}</div>
                    </div>
                </div>
            )
        })
    }

    const success = (data) => {
        //EXTRAT DATA FROM PASS PARAMETER "js feature"
        const { meterNumber, companyName, jobId, periodInMonths, productionDate, expiryDate, isUnderWarranty } = data;

        setLoading(false);
        Modal.success({
            onOk: () => { setSerialNumber(41) },
            content: (
                <div>
                    <h5><b>Warranty Checker</b></h5>
                    <div style={{ marginLeft: 40 }}>
                        <div>Serial Number : {meterNumber} </div>
                        <div>Company Name : {companyName}</div>
                        <div>Reference Number : {jobId}</div>
                        <div>Warranty Period : {periodInMonths} Months</div>
                        <div>Configuration Date : {productionDate} </div>
                        <div>Warranty Expiry Date : {expiryDate} </div>
                        <div>Is Device Under Warranty : { isUnderWarranty ? "Yes" : "No" }</div>
                    </div>
                </div>
            ),
        });
    };

    const scanner = () => {
        setIsScanning(true);
    }

    const doneScanning = () => {
        setIsScanning(false);
    }
    return (
        <div>
            <div className="container grey-text text-darken-1" style={{ marginTop: 20 }}>
                <div className="card-panel white row hoverable">
                    <div className="details blue-text text-darken-4">
                        <div className="center-align blue-text"><h5><b>Welcome to Warranty Checker</b></h5></div>
                        <div className="center-align">Check if your meter is still under warranty by Entering Serial Number</div>
                    </div>
                </div>
                <br />

                <div className="input-field col s6">
                    <div className="row">
                        <i className="material-icons prefix">qr_code_2</i>
                        <input name="serialNumber" id="wmdSerial" type="number" className="validate" disabled={loading}
                            value={serialNumber} onChange={(event) => setSerialNumber(event.target.value)} />
                        <label className="active" htmlFor="wmdSerial">Meter Number</label>

                        <div className="input-field">
                            {!loading &&
                                <button className="btn waves-effect waves-light right blue darken-3" onClick={handleSubmit} disabled={validateForm()}>Submit
                                        <i className="material-icons right">send</i>
                                </button>
                            }
                            {!loading &&
                                <button className="btn waves-effect waves-light left blue darken-3" onClick={scanner}>Scan
                                    <i className="material-icons left">camera</i>
                                </button>
                            }
                        </div>
                        <div className="center-align">
                            <Loader type="Puff"
                                color="#00ACC1"
                                height={120}
                                width={120}
                                visible={loading} />
                        </div>
                        <Modal
                            visible={isScanning}
                            onOk={doneScanning}
                            onCancel={doneScanning}>
                            <div className="responsive-video center-align">
                                <BarcodeScannerComponent
                                    width={250}
                                    height={300}
                                    onUpdate={(err, result) => {
                                        if (result) {
                                            setSerialNumber(result.text);
                                            doneScanning();
                                        }
                                    }} />
                            </div>
                        </Modal>
                    </div>
                </div>

            </div>
        </div >
    )
}
