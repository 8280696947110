import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Footer } from './components/Footer/Footer';
import Navbar from './components/Header/Navbar';
import AboutPage from './views/Home/AboutPage';
import { LandingPage } from './views/Home/LandingPage';
import { ContactPage } from './views/Home/ContactPage';
import NotFoundPage from './views/Home/NotFoundPage';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/home" component={LandingPage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/contact-us" component={ContactPage} />
        <Route component={NotFoundPage}/>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}
export default App;