import React from 'react'

export const Footer = () => {
    return (
        <div>
            <br /><br /><br /><br />
            <footer className="page-footer blue darken-3">
                <div className="footer-copyright blue darken-3">
                    <div className="container center-align">&copy; 2019 WarrantyApp Version 2.0</div>
                </div>
            </footer>
        </div>
    )
}
