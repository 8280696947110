const apiUrl = process.env.REACT_APP_API_URL;

const axios = require('axios');
 
const demoApi = axios.create({
    baseURL: apiUrl
   
});

export const Service = {
    demoApi
}